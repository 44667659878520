import React from 'react';
import '../../styles/home/home-presentation.css'

import { Col, Image, Row, Container } from 'react-bootstrap';
import devicesImage from '../../assets/images/devices.svg';
import appStoreBadgeImage from '../../assets/images/app-store-badge.svg';
import playStoreBadgeImage from '../../assets/images/google-play-badge.svg';

const colSmallSize = 10
const colBigSize = 6

interface StoreButton {
    image: string;
    width: string;
    height: string;
    link: string | undefined;
}

const storeButtons: StoreButton[] = [
    {
        image: appStoreBadgeImage,
        width: "120px",
        height: "40px",
        link: "https://itunes.apple.com/app/id1463157512?mt=8"
    },
    {
        image: playStoreBadgeImage,
        width: "134px",
        height: "40px",
        link: undefined
    }
]

function HomePresentation() {
    return (
        <Container className="d-flex flex-grow-1 justify-content-center align-items-center home-presentation">
            <Row className="d-flex justify-content-center align-items-center">
                {/* <Col className="d-none d-md-block" */}
                <Col className="d-flex justify-content-center align-items-center uptou-device"
                    xs={colSmallSize}
                    sm={colSmallSize}
                    md={colSmallSize}
                    lg={colBigSize}
                    xl={colBigSize} >

                    <Image src={devicesImage} width={"490px"} fluid />

                </Col>
                <Col className="uptou-bio"
                    xs={colSmallSize}
                    sm={colSmallSize}
                    md={colSmallSize}
                    lg={colBigSize}
                    xl={colBigSize} >
                    <Row>
                        <span id="title">
                            Play sharing
                        </span>
                    </Row>
                    <Row>
                        <span id="message">
                            With UPtoU you can play sharing your favourite pictures, within an anonymous community. Only the most voted ones will make it to the top!
                        </span>
                    </Row>
                    <Row id="get-the-app">
                        <span>
                            GET THE APP
                        </span>
                    </Row>
                    <Row>
                        {
                            storeButtons.map((button: StoreButton, index: number) => {
                                return (
                                    <a id="stores-badge" href={button.link}>
                                        <Image
                                            src={button.image}
                                            width={button.width}
                                            height={button.height} />
                                    </a>
                                )
                            })
                        }
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default HomePresentation;
