import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import '../../styles/home/home-footer.css'

interface HomeFooterLink {
    name: string;
    value: string;
}
const links: HomeFooterLink[] = [
    { name: "HELP", value: "mailto:help@uptou.io" },
    { name: "COOKIE", value: "https://www.iubenda.com/privacy-policy/60584930/cookie-policy" },
    { name: "PRIVACY", value: "https://www.iubenda.com/privacy-policy/60584930" },
    { name: "TERMS", value: "https://www.iubenda.com/terms-and-conditions/60584930" }
];

const footerLinkSize = "auto"
const footerCopyrightSize = "auto"

function HomeFooter() {
    return (
        <Container>
            <Row className="justify-content-center align-items-center home-footer" >
                {
                    links.map((link: HomeFooterLink, index: number) => {
                        return (
                            <Col key={index}
                                xs={footerLinkSize}
                                sm={footerLinkSize}
                                md={footerLinkSize}
                                lg={footerLinkSize}
                                xl={footerLinkSize}>
                                <a className="footer-link" href={link.value} target="_blank">
                                    {link.name}
                                </a>
                            </Col>
                        );
                    })
                }
                <Col className="footer-copyright"
                    xs={footerCopyrightSize}
                    sm={footerCopyrightSize}
                    md={footerCopyrightSize}
                    lg={footerCopyrightSize}
                    xl={footerCopyrightSize}>

                    <span className="footer-copyright">© 2020 UPTOU FROM THE ONE WHO MADE IT</span>
                </Col>
            </Row>
        </Container>
    );
}

export default HomeFooter;
