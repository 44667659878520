import React from 'react';

import { Col, Container } from 'react-bootstrap';
import HomePresentation from './homePresentation';
import HomeHeader from './homeHeader';
import HomeFooter from './homeFooter';

const colSmallSize = 12
const colBigSize = 12

function Home() {
  return (
    <Container className="p-0">
      <Col p-0 className="d-flex flex-column min-vh-100 p-0">
        {/* <HomeHeader /> */}
        <HomePresentation />
        <HomeFooter />
      </Col>
    </Container>
  );
}

export default Home;
